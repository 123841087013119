import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../utils/theme'

export const Flex = styled.div`
  display: flex;
`

export const HalfFlexItem = styled.div`
  flex-basis: 50%;
`

export const FlexCenter = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    flex-direction: row;
  }
`

export const Container = styled.div`
  max-width: ${theme.width.max};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: ${props => props.theme.fontSizes[4]};
  }
`

export const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.theme.color.black};
  font-size: ${props => (props.fontSize ? props.fontSize : props.theme.fontSizes[4])};

  border-bottom: 4px solid ${props => props.theme.color.brand};
`

export const Button = styled(Link)`
  background-color: ${props => (props.isOutline ? 'none' : props.theme.color.brand)};
  box-shadow: inset 2px 2px 0 ${props => (props.isOutline ? props.theme.color.brand : 'none')},
    inset -2px -2px 0 ${props => (props.isOutline ? props.theme.color.brand : 'none')};
  border-radius: 4px;
  padding: 10px 16px;
  display: inline-block;

  color: ${props => (props.isOutline ? props.theme.color.brand : 'white')};
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes[5]};
  text-decoration: none;

  transition: 0.2s ease-in;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: ${props => props.theme.fontSizes[4]};
    padding: 15px 22px;
  }

  &:hover {
    background-color: ${props =>
      props.isOutline ? props.theme.color.brand : props.theme.color.brandDark};
    color: white;
  }

  & + & {
    margin-left: 20px;
  }
`

export const Paragraph = styled.p`
  font-size: ${props => props.theme.fontSizes[3]};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1.5rem')};

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`

export const Heading = styled.h1`
  font-size: ${props => props.theme.fontSizes[2]};

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: ${props => props.theme.fontSizes[1]};
  }
`

export const Heading4 = styled.h4`
  font-size: ${props => props.theme.fontSizes[3]};
`

export const FlagHeader = styled.h1`
  font-size: ${props => props.theme.fontSizes[5]};
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacing};
  text-align: center;

  width: 220px;
  margin: 3.5rem auto;
  padding: 12px 0px;

  color: white;
  position: relative;
  background: ${props => props.theme.color.darkGray};

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    border-top: 20px solid ${props => props.theme.color.darkGray};
    border-bottom: 20px solid ${props => props.theme.color.darkGray};
    border-right: 25px solid ${props => props.theme.color.brandBright};
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: ${props => props.theme.fontSizes[4]};
  }
`
