import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import Footer from '../components/Footer'
import SEO from './SEO'

import { ThemeProvider } from 'styled-components'
import theme from '../utils/theme'
import './all.sass'

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <div>
          <SEO />
          <div>{children}</div>
          <Footer />
        </div>
      </ThemeProvider>
    )}
  />
)

export default TemplateWrapper
