// Color
const color = {
  brand: '#c69f6e',
  brandDark: '#321E41',
  brandBright: '#f1e8dc',

  fontColor: '#555555',

  lightGray: '#FAFAFA',
  cyan: '#CDF6FF',

  darkGray: '#888888',
  black: '#555555',
  white: 'white',
}

// Font
const fontSizes = ['36px', '24px', '20px', '18px', '16px', '14px']
const fontWeight = { regular: 'regular', medium: '500', bold: 'bold' }
const fontPrimary =
  'Muli,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
const fontSecondary = 'Roboto, Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif'
const fontSerif = 'Georgia, Garamond, serif'
const letterSpacing = '1.5px'

// Box Shadow
const shadowColor = 'rgba(0,0,0,0.125)'
const baseShadow = '0 0 2px 0 rgba(0,0,0,.0625),'
const boxShadows = [
  baseShadow + `0 2px 4px 0 ${shadowColor}`,
  baseShadow + `0 4px 8px 0 ${shadowColor}`,
  baseShadow + `0 12px 12px 0 ${shadowColor}`,
  baseShadow + `0 24px 24px 0 ${shadowColor}`,
]

// Layout
const breakpoint = {
  phone: '576px',
  tablet: '768px',
  desktop: '992px',
  large: '1200px',
  wide: '1440px',
}
const max = '1024px'
const width = { max }

const theme = {
  color,
  fontSizes,
  fontWeight,
  fontPrimary,
  fontSecondary,
  fontSerif,
  letterSpacing,
  width,
  boxShadows,
  breakpoint,
}

export default theme
