import { Flex } from './design-system'
// import FacebookIcon from "../img/fb.inline.svg"
import InstagramIcon from '../img/insta.inline.svg'
import { Link } from 'gatsby'
import Logo from '../img/logo.inline.svg'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.StyledLogo = styled(Logo)`
  path,
  polygon {
    fill: ${props => props.theme.color.brand};
  }

  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    margin: 0;
  }
`

S.NavSection = styled(Flex)`
  flex-direction: column;
  align-items: center;

  width: unset;
  background: ${props => props.background};
  color: #999;
  padding: 2em 1.5em;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: ${props => props.width};
    flex-direction: row;
    justify-content: space-between;
  }
`

S.NavLink = styled(Link)`
  font-size: 12px;
  color: #999;
  text-decoration: none;

  & + & {
    margin-left: 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 12px;
  }
`

S.Uppercase = styled.p`
  text-transform: uppercase;
  font-weight: 600;
  font-family: ${props => props.theme.fontPrimary};
  font-size: 12px;
  color: #555;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 12px;
  }
`

S.Social = styled.div`
  text-align: center;
  a {
    color: ${props => props.theme.color.darkGray};
  }
  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    text-align: right;
  }
`

S.Copyright = styled.p`
  font-size: 12px;
  margin-bottom: 0;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 12px;
  }
`
/* random comment, can be removed */

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <S.NavSection background="#FFF">
          <S.StyledLogo />
          <div>
            <S.Uppercase>Connect with Us</S.Uppercase>
            <S.Social>
              <a
                href="https://www.instagram.com/whatsontap.kl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            </S.Social>
          </div>
        </S.NavSection>

        <S.NavSection background="#F6F6F6">
          <S.Copyright>
            © 2019 <strong>What’s On Tap</strong>. All rights reserved.
          </S.Copyright>




          <div>
            <S.NavLink to="/privacy-policy">Privacy Policy</S.NavLink>
            <S.NavLink to="/terms-and-conditions">Terms and Conditions</S.NavLink>
          </div>
        </S.NavSection>
      </footer>
    )
  }
}

export default Footer
